import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import ArrowWhite from './img/arrow.png';
import ArrowOrange from './img/arrow-orange.png';
import videoFile from '../../assets/images/video.mp4';

const pulse = color => keyframes`
    0% {
      opacity: 0.6;
    }
    
    50% {
      opacity: 0;
    }
    
    100% {
      opacity: 0.6;
    }
`;

const Btn = styled.button`
  background-color: ${props => props.color};
  border: none;
  width: ${props => (props.size === 'big' ? '136px' : '76px')};
  height: ${props => (props.size === 'big' ? '136px' : '76px')};
  border-radius: 50%;
  box-shadow: 0px 3px 125px ${props => props.color};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: transform 0.3s;
  backface-visibility: hidden;
  perspective: 1000px;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: ${props => props.color};
    position: absolute;
    top: 50%;
    left: 50%;
    box-shadow: 0 0 14px 4px #00dda9;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: 0;
    animation: ${props => pulse(props.color)} 3s linear infinite;
    backface-visibility: hidden;
    perspective: 1000px;
  }

  &:hover {
    transform: scale(1.09);
  }

  &:active {
    transform: scale(1.045);
  }

  img {
    z-index: 3;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.desktopSm}) {
    width: ${props => (props.size === 'big' ? '96px' : '68px')};
    height: ${props => (props.size === 'big' ? '96px' : '68px')};
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    width: ${props => (props.size === 'big' ? '96px' : '54px')};
    height: ${props => (props.size === 'big' ? '96px' : '54px')};
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  display: flex;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(58, 13, 144, 0.96);
  z-index: 2;
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s;
  padding: 80px 0 0;
    
  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 0;
  }

  &.open {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.3s;
  }
`;

const Modal = styled.div`
  max-width: calc(100% - 20px);
  max-height: calc(100vh - 140px);
  position: relative;
  transform: translateY(40px);
  transition: 0.3s;
  
  &.open {
    transform: translateY(0px)
  }
  
  video {
    width: 1024px;
    max-width: 100%;
    max-height: calc(100vh - 140px);
    
    @media screen and (max-width: ${props => props.theme.breakpoints.desktopMed}) {
      width: 900px;
    }
    
    @media screen and (max-width: ${props => props.theme.breakpoints.desktopSm}) {
      width: 720px;
    }
    
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      width: 480px;
    }
    
    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
      width: 100%;
    }
  }
  
  .exit {
    position: absolute;
    width: 26px;
    height: 26px;
    cursor: pointer;
    top: -30px;
    right: -30px;
    
    @media screen and (max-width: 550px) {
      right: 0px;
      top: -40px;
    }
    
    &::before, &::after {
      display: block;
      content: '';
      width: 100%;
      height: 3px;
      border-radius: 2px;
      background-color: rgb(185, 185, 185);
    }
    
    &::before {
      transform: rotate(45deg) translate(8px, 8px);
    }
    
    &::after {
      transform: rotate(-45deg) translate(-6px,6px);
    }
  }
`;

const BtnPlay = ({ color, size }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasBeenOpened, setHasBeenOpened] = useState(false);

  const toggle = () => {
    const videoPlayer = document.querySelector('video');
    setIsOpen(!isOpen);

    videoPlayer.pause();

    if(!hasBeenOpened) {
      videoPlayer.play();
    }

    setHasBeenOpened(true);
  };
  let btnColor = color === 'green' ? '#00dda9' : '#fff';
  let arrowVariant = color === 'green' ? ArrowWhite : ArrowOrange;

  useEffect(() => {
    const videoPlayer = document.querySelector('video');

    window.addEventListener('keyup', (e) => {
      if (e.key === 'Escape' && isOpen) {
        setIsOpen(false);
        setHasBeenOpened(true);
        videoPlayer.pause();
      }
    });
  });


  return (
      <>
        <Btn color={btnColor} size={size} onClick={() => toggle()}>
          <img src={arrowVariant} alt="" />
        </Btn>
        <ModalContainer className={isOpen ? 'open' : ''}>
          <Modal className={isOpen ? 'open' : ''}>
            <video controls>
              <source src={videoFile} type="video/mp4" />
            </video>
            <span className="exit" onClick={() => toggle()}></span>
          </Modal>
        </ModalContainer>
      </>
  );
};

export default BtnPlay;
