import React, { createRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import lottie from 'lottie-web';
import animation from './lottieData.json';
import HeroBg from './img/hero-bg.png';
// import HeroImg from './img/hero-img.png';
// import HeroImgMobile from './img/hero-img-mobile.png';
import BtnPlay from '../BtnPlay/BtnPlay';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Link } from 'gatsby';
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Background = styled.header`
  background-image: url(${HeroBg});
  background-size: cover;
  background-position: center;
  color: #fff;
`;

const Container = styled.div`
  max-width: 1690px;
  width: 100%;
  margin: 0 auto;
  padding: 110px 65px 50px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow-x: hidden;

  @media screen and (max-width: ${props => props.theme.breakpoints.desktopSm}) {
    padding: 110px 30px 40px;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 130px 30px 50px;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 96px 30px 50px;
  }
`;

const Column = styled.div`
  flex: 0 0 50%;
  max-width: 50%;

  &:last-child {
    padding-left: 15px;

    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      padding-left: 0px;
    }
  }

  & > img {
    right: 0;
    top: 0;
    max-width: 100%;
    height: auto;

    @media screen and (max-width: ${props =>
        props.theme.breakpoints.desktopLrg}) {
      margin-top: 0;
    }

    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
      display: none;
    }

    &.HeroImgMobile {
      display: none;

      @media screen and (max-width: ${props =>
          props.theme.breakpoints.mobile}) {
        width: calc(100% + 60px);
        max-width: initial;
        margin: 0 -30px 0;
        display: block;
      }
    }
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 20px;
  }
`;

const Headline = styled.h1`
  font-size: 83px;
  font-weight: 200;
  line-height: 1.1;

  strong {
    font-weight: 600;
  }

  @media screen and (max-width: ${props =>
      props.theme.breakpoints.desktopMed}) {
    font-size: 60px;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.desktopSm}) {
    font-size: 52px;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 32px;
  }
`;

const Paragraph = styled.p`
  margin-top: 24px;
  margin-bottom: 50px;
  font-weight: 300;
  font-size: 20px;
  color: #c8bfe0;
  line-height: 1.5;

  @media screen and (max-width: ${props =>
      props.theme.breakpoints.desktopMed}) {
    font-size: 18px;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.desktopSm}) {
    margin-bottom: 26px;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 16px;
    margin-top: 16px;
  }
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const BtnMore = styled(Link)`
  display: block;
  margin-right: 24px;
  padding: 24px 52px;
  border-radius: 200px;
  background-image: linear-gradient(to bottom right, #f38a27, #efba35);
  color: #fff;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  border: none;
  transition: transform 0.3s;
  text-decoration: none;

  &:hover {
    transform: scale(1.06);
  }

  &:active {
    transform: scale(1.03);
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.desktopSm}) {
    padding: 20px 48px;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 14px;
    padding: 16px 35px;
    margin-right: 16px;
  }
`;

export const BtnMoreAnchor = styled(AnchorLink)`
  display: block;
  margin-right: 24px;
  padding: 24px 52px;
  border-radius: 200px;
  background-image: linear-gradient(to bottom right, #f38a27, #efba35);
  color: #fff;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  border: none;
  transition: transform 0.3s;
  text-decoration: none;

  &:hover {
    transform: scale(1.06);
  }

  &:active {
    transform: scale(1.03);
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.desktopSm}) {
    padding: 20px 48px;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 14px;
    padding: 16px 35px;
    margin-right: 16px;
  }
`;

export const BtnMoreInternal = styled.a`
  cursor: pointer;
  display: block;
  margin-right: 24px;
  padding: 24px 52px;
  border-radius: 200px;
  background-image: linear-gradient(to bottom right, #f38a27, #efba35);
  color: #fff;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  border: none;
  transition: transform 0.3s;
  text-decoration: none;

  &:hover {
    transform: scale(1.06);
  }

  &:active {
    transform: scale(1.03);
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.desktopSm}) {
    padding: 20px 48px;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 14px;
    padding: 16px 35px;
    margin-right: 16px;
  }
`;

const pulse = keyframes`
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
`;

const LottieWrapper = styled.div`
  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin-top: 30px;
    
    svg {
      transform: scale(1.2);
    }
  } 
`;

const Hero = () => {
  const animationContainer = createRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animation,
      assetsPath: './images/',
      rendererSettings: {
        preserveAspectRatio: 'xMaxYMax slice',
        viewBoxOnly: true,
        viewBoxSize: "360 240 500 470",
      },
    });
    return () => anim.destroy();
  }, []);

  return (
    <Background>
      <Container>
        <Column className="ColumnLeft">
          <Headline>
            <strong>Monetyzuj</strong> swoje zasięgi!
          </Headline>
          <Paragraph>
            Stworzymy i poprowadzimy dla Ciebie sklep z Twoimi produktami.
          </Paragraph>
          <BtnWrapper>
            <span>
              <BtnMoreInternal onClick={() => scrollTo('#dla-kogo')}>Dowiedz się więcej</BtnMoreInternal>
            </span>
            <BtnPlay color="green" />
          </BtnWrapper>
        </Column>
        <Column>
          {/* <img src={HeroImg} />
          <img className="HeroImgMobile" src={HeroImgMobile} /> */}
          <LottieWrapper ref={animationContainer}></LottieWrapper>
        </Column>
      </Container>
    </Background>
  );
};

export default Hero;
